
import { defineComponent, reactive, ref, computed, watchEffect } from 'vue'
import axios from '@/http'
import { enumLotStates, localizeLotStates } from '@/constains'
import { IGetLots, IGetLotsRes, ILot } from '@/interfaces'
import { getNoun } from '@/utils/getNoun'
import { prettyPrice } from '@/utils/prettyPrice'

export default defineComponent({
	name: 'LotsPage',

	setup() {
		const pageNumber = ref<number>(1)
		let lots = reactive<Array<ILot>>([])
		let count = ref<number>(0)
		let countLoad = 10
		const pagerCount = 3
		const pageCount = computed<number>(() => Math.ceil(count.value / countLoad))

		watchEffect(() => changePage(pageNumber.value))

		async function getLotList(page: number, limit = countLoad): Promise<IGetLotsRes> {
			let res = null

			try {
				res = (await axios.post('/lot/list', { page: page - 1, limit } as IGetLots)).data
			} catch (e) {}

			return res
		}

		async function changePage(page: number) {
			const resData = await getLotList(page)

			if (resData?.rows) {
				count.value = resData.count
				lots.splice(0, lots.length, ...resData.rows)
			}
		}

		function getQuantityText(n: number) {
			return n + ' ' + getNoun(n, 'вещь', 'вещи', 'вещей')
		}

		return {
			pageCount,
			lots,
			pageNumber,
			pagerCount,
			localizeLotStates,
			enumLotStates,
			prettyPrice,
			getQuantityText
		}
	}
})
